import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ThemeSwitcher = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ mode }) => (mode === "dark" ? "black" : "white")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  color: ${({ mode }) => (mode === "dark" ? "white" : "black")};
`;

const DarkModeToggle = () => {
  const h = "nightowl-color-scheme";
  const l = "light";
  const o = "dark";
  const [theme, setTheme] = useState(l);

  useEffect(() => {
    const savedTheme = localStorage.getItem(h);
    if (savedTheme) {
      setTheme(savedTheme);
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setTheme(o);
    }

    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .nightowl-light body {
        filter: invert(0%);
      }
      .nightowl-dark {
        background-color: #111;
      }
      .nightowl-dark body {
        filter: invert(100%) hue-rotate(180deg);
      }
      .nightowl-dark img, .nightowl-dark video, .nightowl-dark iframe {
        filter: invert(100%) hue-rotate(180deg);
      }
      .nightowl-dark .icon {
        filter: invert(15%) hue-rotate(180deg);
      }
      .nightowl-dark pre {
        filter: invert(6%);
      }
      .nightowl-dark li::marker {
        color: #666;
      }
    `;
    document.head.appendChild(styleElement);

    // Apply theme class to html element
    document.documentElement.classList.toggle("nightowl-dark", theme === o);
    document.documentElement.classList.toggle("nightowl-light", theme === l);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === o ? l : o;
    setTheme(newTheme);
    localStorage.setItem(h, newTheme);
  };

  return (
    <ThemeSwitcher mode={theme} onClick={toggleTheme}>
      {theme === o ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          style={{ width: "25px", height: "25px" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          style={{ width: "25px", height: "25px" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
          />
        </svg>
      )}
    </ThemeSwitcher>
  );
};

export default DarkModeToggle;
